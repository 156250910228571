// 8 point grid breakpoints
@mixin breakpoint($class) {
  @if $class == apples {
    @media (max-width: 320px) {
      @content;
    }
  }

  @if $class == xs {
    @media (max-width: $breakpoint-xs) {
      @content;
    }
  } @else if $class == sm {
    @media (max-width: $breakpoint-sm) {
      @content;
    }
  } @else if $class == md {
    @media (max-width: $breakpoint-md) {
      @content;
    }
  } @else if $class == lg {
    @media (max-width: $breakpoint-lg) {
      @content;
    }
  } @else if $class == xl {
    @media (max-width: $breakpoint-xl) {
      @content;
    }
  } @else {
    @media (max-width: $class) {
      @content;
    }
  }
}

@mixin breakpoint-from($class) {
  @if $class == apples {
    @media (min-width: 321px) {
      @content;
    }
  }

  @if $class == xs {
    @media (min-width: $breakpoint-xs + 1px) {
      @content;
    }
  } @else if $class == sm {
    @media (min-width: $breakpoint-sm + 1px) {
      @content;
    }
  } @else if $class == md {
    @media (min-width: $breakpoint-md + 1px) {
      @content;
    }
  } @else if $class == lg {
    @media (min-width: $breakpoint-lg + 1px) {
      @content;
    }
  } @else if $class == xl {
    @media (min-width: $breakpoint-xl + 1px) {
      @content;
    }
  } @else {
    @media (min-width: $class) {
      @content;
    }
  }
}

@mixin container {
  display: flex;
  max-width: $container-lg-width;
  margin: 0 auto;
  flex-wrap: wrap;

  @media (max-width: $breakpoint-lg) {
    max-width: $container-md-width;
  }

  @media (max-width: $breakpoint-md) {
    max-width: $container-sm-width
  }

  @media (max-width: $breakpoint-sm) {
    max-width: 480px;
  }
}

@mixin container-block {
  display: block;
  width: 100%;
  max-width: $container-lg-width;
  padding-left: $gutter;
  padding-right: $gutter;
  margin: 0 auto;

  @media (max-width: $breakpoint-lg) {
    max-width: $container-md-width;
  }

  @media (max-width: $breakpoint-md) {
    max-width: $container-sm-width
  }

  @media (max-width: $breakpoint-sm) {
    max-width: 480px;
  }
}

@mixin wide-container {

  display: flex;
  max-width: $container-xl-width;
  margin: 0 auto;
  flex-wrap: wrap;

  @media (max-width: $breakpoint-xl) {
    max-width: $container-lg-width;
  }

  @media (max-width: $breakpoint-lg) {
    max-width: $container-md-width;
  }

  @media (max-width: $breakpoint-md) {
    max-width: $container-sm-width
  }

  @media (max-width: $breakpoint-sm) {
    max-width: 480px;
  }
}



// make container mixin
@mixin custom-container($columns: 16) {

  $breakpoint-lg: $columns * ($column-lg-width + (2 * $gutter)) - 1;
  $breakpoint-md: $columns * ($column-md-width + (2 * $gutter)) - 1;
  $breakpoint-sm: $columns * ($column-sm-width + (2 * $gutter)) - 1;

  display: flex;
  max-width: $columns * ($column-lg-width + (2 * $gutter));
  margin: 0 auto;
  flex-wrap: wrap;

  @media (max-width: $breakpoint-lg) {
    max-width: $columns * ($column-md-width + (2 * $gutter));
  }

  @media (max-width: $breakpoint-md) {
    max-width: $columns * ($column-sm-width + (2 * $gutter));
  }

  @media (max-width: $breakpoint-sm) {
    max-width: 480px;
  }
}

// make column mixin
@mixin column($number, $width-only: false) {
  @if $width-only == true {
    flex-basis: percentage($number);
  } @else {
    display: block;
    padding-left: $gutter;
    padding-right: $gutter;
    flex-basis: percentage($number);
  }
}

@mixin push($number, $direction: left) {
  @if $direction == "left" {
    margin-left: percentage($number);
  } @else if $direction == "both" {
    margin-left: percentage($number);
    margin-right: percentage($number);
  } @else {
    margin-right: percentage($number);
  }
}

// pseudo elements
@mixin ps($display: block, $pos: absolute, $content: "") {
  display: $display;
  position: $pos;
  content: $content;
}

@mixin aspect-ratio($width, $height) {
  padding-top: ($height / $width) * 100%;
}

@mixin space-y($space: 16px) {
  > * {
    margin: 0;
  }

  >:not([hidden])~:not([hidden]) {
    margin-top: $space;
  }
}

// @mixin badge() {
//   display: inline-block;
//   transition: transform 0.7s ease-out, opacity 0.3s ease-in;
//   position: absolute;
//   left: 50%;
//   transform: translateX(-50%);
//   z-index: $z-index-badge;

//   &.counterFinished, &.loaderShown {
//     .imageWrapper {
//       &:before {
//         opacity: 1;
//       }
//     }
//   }

//   &.atPreBottom {
//     opacity: 0;
//     pointer-events: none;
//   }
// }

// @mixin counterWrapper()  {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate3d(-50%, -50%, 0);

//   font-size: 16.5px;
//   font-weight: bold;
//   letter-spacing: 1.2px;

//   transition: opacity 1s ease-out;
// }

// @mixin badgeImageWrapper() {
//   position: relative;
//   width: $badge-width * 1.2;
//   height: $badge-width * 1.2;

//   &:hover {
//     cursor: pointer;
//   }

//   @keyframes spin {
//     100% {
//       transform: rotate(360deg);
//     }
//   }

//   // arrow
//   &:before {
//     @include ps;
//     top: 50%;
//     left: 50%;
//     transform: translate3d(-50%, -50%, 0);
//     width: 10px;
//     height: 25px;
//     background: url("/images/explore-badge-arrow-down.svg") center center
//       no-repeat;
//     transition: transform 0.1s ease-out, opacity 0.1s ease-out;
//     opacity: 0;
//   }

//   // violet background
//   &:after {
//     @include ps;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background-color: rgba(0, 0, 255, 0.1);
//     border-radius: 50%;
//     z-index: -1;
//     pointer-events: none;
//     transition: transform 0.3s ease-out;

//     @include breakpoint(sm) {
//       display: none;
//     }
//   }

//   img {
//     animation: spin 4s linear;
//     animation-iteration-count: infinite;
//   }
// }