@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
.topBar {
  height: 60px;
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 6px 16px 4px 16px;
  border-bottom: 1px solid #0000ff1a;
  margin-bottom: 24px;
}

.logo {
  display: flex;
}

.userInfo {
  display: flex;
  align-items: center;
  column-gap: 16px;
}

.welcome {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  text-align: center;
  padding: 0 16px;
}

.container {
  // max-width: 1140px;
  max-width: 1521px;
  width: 100%;
  margin: 0 auto 32px auto;
  padding: 0 32px;

  @include breakpoint(1280px) {
    padding: 0 16px;
  }
}

.userSelect {
  padding: 6px 12px;
  display: block;
  margin: 0 auto 12px auto;
  border: 1px solid #e5e5ff;
  border-radius: 8px;
  cursor: pointer;
  background: white;
  transition: border-color 0.1s ease-in-out;
}

.topPart {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  margin-bottom: 32px;
}

.dateChanger {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  gap: 32px;

  @include breakpoint(1500px) {
    gap: 16px;
  }
}

.dateChangerTxt {
  font-size: 24px;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  margin: 0;

  @include breakpoint(1500px) {
    font-size: 20px;
  }

  @include breakpoint(1024px) {
    font-size: 18px;
  }

  @include breakpoint(960px) {
    display: flex;
    flex-direction: column;
  }

  @include breakpoint(600px) {
    font-size: 16px;
  }

  .dateChangerDate {
    span {
      @include breakpoint(1024px) {
        display: none;
      }
    }
  }
}

.dateChangerBtn {
  padding: 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: 1.2;
  gap: 16px;
  border: 1px solid #e5e5ff;
  border-radius: 8px;
  cursor: pointer;
  background: white;
  transition: border-color 0.1s ease-in;

  &:hover {
    border: 1px solid #c0c0f5;
  }

  @include breakpoint(1500px) {
    padding: 8px 12px;
    gap: 8px;
  }

  @include breakpoint(1024px) {
    padding: 8px;
    gap: 8px;
    font-size: 14px;
  }

  &.dateChangerBtnIcoLeft {
    padding-left: 8px;

    @include breakpoint(1500px) {
      padding-left: 4px;
    }
  }

  &.dateChangerBtnIcoRight {
    padding-right: 8px;

    @include breakpoint(1500px) {
      padding-right: 4px;
    }
  }

  span {
    @include breakpoint(600px) {
      display: none;
    }
  }
}

.loginBtn {
  // TODO: reuse these btn  styles across HDD
  background: #5626f7;
  color: white;
  border: none;
  box-shadow: none;
  outline: none;
  gap: 8px;
  padding: 5px 8px 3px 8px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  transition: background-color 0.1s ease-in-out;
  cursor: pointer;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 32px;

  &:hover {
    background: #3f17c3;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #9e9ef2;
  }
}

.welcomeBtn {
  // TODO: reuse these btn  styles across HDD
  background: #5626f7;
  color: white;
  border: none;
  box-shadow: none;
  outline: none;
  gap: 8px;
  padding: 10px 20px;
  font-size: 20px;
  font-weight: 500;
  border-radius: 4px;
  transition: background-color 0.1s ease-in-out;
  cursor: pointer;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 32px;

  &:hover {
    background: #3f17c3;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #9e9ef2;
  }
}

.codeWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-bottom: 64px;
}

.code {
  display: inline-block;
  background-color: #EEEEEE;
  padding: 16px;
}

.welcomeDontShow {
  display: flex;
  column-gap: 8px;
  align-items: center;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.weekTableWrapper {
  position: relative;

  &.weekTableWrapperLoading {
    .weekTableOuter {
      animation: pulse 1.2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
      animation-direction: reverse;
    }
  }
}

.weekTableOuter {
  position: relative;
}

.weekTableOuterSpinner {
  position: absolute;
  left: calc(50% - 16px);
  top: calc(50% - 16px);
  z-index: 30;
}

.weekTableLoader {
  position: absolute;
  z-index: 10;
  top: 24px;
  left: calc(50% - 40px);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  line-height: 1.1;
  padding: 0.1em 0 0 0;
  margin: 0;
  width: 80px;
  height: 80px;
  background: white;
  border-radius: 50%;
}

.adminRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin: 150px 0;

  @include breakpoint(1024px) {
    grid-template-columns: 1fr;
  }

  @include breakpoint(640px) {
    display: none;
  }
}

.adminForm {
  display: flex;
  flex-direction: column;
  gap: 16px;

  input,
  button {
    padding: 8px;
  }
}
