/**
 * Settings
 */

$sal-animation-duration: 0.4s !default;
$sal-animation-delay: 0s !default;
$sal-slide-offset: 20% !default;
$sal-zoom-in-scale: 0.5 !default;
$sal-zoom-out-scale: 1.1 !default;
$sal-flip-rotate: 91deg !default;

/**
  * Easings
  */

$sal-easings: (
  linear: linear,
  ease: ease,
  ease-in: ease-in,
  ease-out: ease-out,
  ease-in-out: ease-in-out,

  ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19),
  ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1),
  ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1),
  ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335),
  ease-out-circ: cubic-bezier(0.075, 0.82, 0.165, 1),
  ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86),
  ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035),
  ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1),
  ease-in-out-expo: cubic-bezier(1, 0, 0, 1),
  ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53),
  ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94),
  ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955),
  ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22),
  ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1),
  ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1),
  ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06),
  ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1),
  ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1),
  ease-in-sine: cubic-bezier(0.47, 0, 0.745, 0.715),
  ease-out-sine: cubic-bezier(0.39, 0.575, 0.565, 1),
  ease-in-out-sine: cubic-bezier(0.445, 0.05, 0.55, 0.95),
  ease-in-back: cubic-bezier(0.6, -0.28, 0.735, 0.045),
  ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275),
  ease-in-out-back: cubic-bezier(0.68, -0.55, 0.265, 1.55),
);

/**
  * Core
  */

[data-sal] {
  transition-duration: $sal-animation-duration;
  transition-delay: $sal-animation-delay;
  transition-duration: var(--sal-duration, $sal-animation-duration);
  transition-delay: var(--sal-delay, $sal-animation-delay);
  transition-timing-function: var(--sal-easing, map-get($sal-easings, "ease"));
}

@for $i from 4 through 40 {
  [data-sal][data-sal-duration="#{$i * 50}"] {
    transition-duration: #{$i * 0.05}s;
  }
}

@for $i from 1 through 20 {
  [data-sal][data-sal-delay="#{$i * 50}"] {
    transition-delay: #{$i * 0.05}s;
  }
}

@each $key, $value in $sal-easings {
  [data-sal][data-sal-easing="#{$key}"] {
    transition-timing-function: $value;
  }
}

/**
  * Animations
  */

// Fade
[data-sal|="fade"] {
  opacity: 0;
  transition-property: opacity;
}

[data-sal|="fade"].sal-animate,
body.sal-disabled [data-sal|="fade"] {
  opacity: 1;
}

// Slide
[data-sal|="slide"] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-sal="slide-up"] {
  transform: translateY($sal-slide-offset);
}

[data-sal="slide-down"] {
  transform: translateY(-$sal-slide-offset);
}

[data-sal="slide-left"] {
  transform: translateX($sal-slide-offset);
}

[data-sal="slide-right"] {
  transform: translateX(-$sal-slide-offset);
}

[data-sal|="slide"].sal-animate,
body.sal-disabled [data-sal|="slide"] {
  opacity: 1;
  transform: none;
}

// Zoom
[data-sal|="zoom"] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-sal="zoom-in"] {
  transform: scale($sal-zoom-in-scale);
}

[data-sal="zoom-out"] {
  transform: scale($sal-zoom-out-scale);
}

[data-sal|="zoom"].sal-animate,
body.sal-disabled [data-sal|="zoom"] {
  opacity: 1;
  transform: none;
}

// Flip
[data-sal|="flip"] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-sal="flip-left"] {
  transform: perspective(2000px) rotateY(-$sal-flip-rotate);
}

[data-sal="flip-right"] {
  transform: perspective(2000px) rotateY($sal-flip-rotate);
}

[data-sal="flip-up"] {
  transform: perspective(2000px) rotateX(-$sal-flip-rotate);
}

[data-sal="flip-down"] {
  transform: perspective(2000px) rotateX($sal-flip-rotate);
}

[data-sal|="flip"].sal-animate,
body.sal-disabled [data-sal|="flip"] {
  transform: none;
}
