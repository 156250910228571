@use "sass:math"; @import "variables.scss"; @import "mixins.scss";
@import "normalize.css/normalize.css";
@import "./slim-select.css";
@import "./fonts.scss";
@import "./sal.scss";

* {
  box-sizing: border-box;
}

a,
a:visited {
  color: inherit;
}

#body {
  &.popupLock {
    overflow: hidden;
  }
}