/* open-sans-regular - latin-ext_latin */
// @font-face {
//   font-family: "Open Sans";
//   font-style: normal;
//   font-weight: 400;
//   src: local(""),
//     url("/fonts/OpenSans/open-sans-v27-latin-ext_latin-regular.woff2")
//       format("woff2"),
//     /* Chrome 26+, Opera 23+, Firefox 39+ */
//       url("/fonts/OpenSans/open-sans-v27-latin-ext_latin-regular.woff")
//       format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
// }
// /* open-sans-700 - latin-ext_latin */
// @font-face {
//   font-family: "Open Sans";
//   font-style: normal;
//   font-weight: 700;
//   src: local(""),
//     url("/fonts/OpenSans/open-sans-v27-latin-ext_latin-700.woff2")
//       format("woff2"),
//     /* Chrome 26+, Opera 23+, Firefox 39+ */
//       url("/fonts/OpenSans/open-sans-v27-latin-ext_latin-700.woff")
//       format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
// }

/* lufga regular */
@font-face {
  font-family: "Lufga";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("/fonts/Lufga/Lufga-Regular.otf") format("opentype");
  font-display: swap;
}

/* lufga medium */
@font-face {
  font-family: "Lufga";
  font-style: normal;
  font-weight: 500;
  src: local(""), url("/fonts/Lufga/Lufga-Medium.otf") format("opentype");
  font-display: swap;
}

/* lufga semi-bold */
@font-face {
  font-family: "Lufga";
  font-style: normal;
  font-weight: 600;
  src: local(""), url("/fonts/Lufga/Lufga-SemiBold.otf") format("opentype");
  font-display: swap;
}

/* lufga bold */
@font-face {
  font-family: "Lufga";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("/fonts/Lufga/Lufga-Bold.otf") format("opentype");
  font-display: swap;
}

html,
body {
  font-family: "Lufga", Verdana, sans-serif;
  color: $text-color;
  font-size: 16px;
}
