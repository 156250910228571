// grid
$gutter: 16px;
$column-lg-width: 64px;
$column-md-width: 48px;
$column-sm-width: 32px;
$column-xs-width: 100%;

$container-xl-width: 1536px;
$container-lg-width: 1152px;
$container-md-width: 960px;
$container-sm-width: 768px;
$container-xs-width: 100%;

$breakpoint-xl: $container-xl-width - 1;
$breakpoint-lg: $container-lg-width - 1;
$breakpoint-md: $container-md-width - 1;
$breakpoint-sm: $container-sm-width - 1;
$breakpoint-xs: 480px;

// colors

$black: #1c1c1c;
$black2: #151522;
$white: #ffffff;

$text-color: $black;

// zindexes

$z-index-over-content: 100;
$z-index-badge: 110;
$z-index-menu: 5000;

$top-bar-height-desktop: 42px;
//$top-bar-height-mobile: 42px;

$header-height-desktop: 96px;
$header-height-mobile: 52px;

$header-breakpoint: $breakpoint-md;

// badge sizes

$badge-width: 115px;

// borders

$images-border-radius: 18px;